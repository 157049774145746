import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Button, Typography, Tabs, Tab, Snackbar, Alert, CircularProgress, Paper } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EnhanceIcon from "@mui/icons-material/AutoFixHigh";
import CleanIcon from "@mui/icons-material/CleaningServices";
import SyncIcon from "@mui/icons-material/Sync";
import AlignedTitle from "./AlignedTitle";
import { useLoaderData, useOutletContext, useSearchParams } from "react-router";
import { authedApiFetch } from "../../utils/Api";
import AscentUpload from "./AscentUpload";
import MappingReview from "./MappingReview";
import FileViewer from "./FileViewer";
import EmailSyncPanel from "./EmailSyncPanel";

const FileStatus = {
  UPLOADED: "uploaded",
  PROCESSING: "processing",
  MAPPING_READY: "mapping_ready",
  MAPPING_IN_PROGRESS: "mapping_in_progress",
  MAPPING_COMPLETE: "mapping_complete",
  PROCESSING_COMPLETE: "processing_complete",
  ERROR: "error",
};

export const ascentLoader = async ({ params }) => {
  const { tenantId } = params;

  const files = await authedApiFetch({
    endpoint: `/tenants/${tenantId}/ascent_files`,
    method: "GET",
  });

  return {
    tenantId,
    uploadedFiles: files.map((file) => ({
      id: file.id,
      fileName: file.original_file_name,
      object: file.target_object,
      dateCreated: new Date(file.created_at).toISOString().split("T")[0],
      owner: file.user_id,
      status: file.status,
      mappingConfidence: file.metadata?.mapping_confidence || null,
      databricks_table_name: file.databricks_table_name,
    })),
  };
};

const Ascent = () => {
  // React Router hooks
  const { tenantId, uploadedFiles: initialFiles } = useLoaderData();
  let [_searchParams, setSearchParams] = useSearchParams();
  const { user } = useOutletContext();

  const [tabIndex, setTabIndex] = useState(0);
  const [notification, setNotification] = useState(null);
  const [processingFile, setProcessingFile] = useState(null);
  const [mappingReview, setMappingReview] = useState(null);
  const [viewingFile, setViewingFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  // Track files that have been uploaded but not yet appeared in the table
  const [pendingFiles, setPendingFiles] = useState([]);
  // Track files that are in the table but still processing
  const [processingFileIds, setProcessingFileIds] = useState(new Set());

  // Set up polling when files are uploading or processing
  useEffect(() => {
    if (pendingFiles.length === 0 && processingFileIds.size === 0) return;
    
    // Check for updates every 5 seconds
    // This also means the visible counter updates every 5 seconds. this is deliberate
    const interval = setInterval(() => {
      refreshFileList();
    }, 5000);
    
    // Clean up on unmount
    return () => clearInterval(interval);
  }, [pendingFiles.length, processingFileIds.size, tenantId]);

  // Function to refresh the file list
  const refreshFileList = async () => {
    try {
      const updatedFiles = await authedApiFetch({
        endpoint: `/tenants/${tenantId}/ascent_files`,
        method: "GET",
      });

      const formattedFiles = updatedFiles.map((file) => ({
        id: file.id,
        fileName: file.original_file_name,
        object: file.target_object || "Detecting...",
        dateCreated: new Date(file.created_at).toISOString().split("T")[0],
        owner: file.user_id,
        status: file.status,
        mappingConfidence: file.metadata?.mapping_confidence || null,
        databricks_table_name: file.databricks_table_name,
      }));

      // Check for pending files that have now appeared in the list
      const newPendingFiles = [...pendingFiles];
      const pendingFilesFound = [];
      
      for (let i = 0; i < newPendingFiles.length; i++) {
        const pendingFile = newPendingFiles[i];
        const foundFile = formattedFiles.find(file => file.fileName === pendingFile.fileName);
        
        if (foundFile) {
          pendingFilesFound.push({file: foundFile, index: i});
          
          // Add to processing IDs if still processing
          if (foundFile.status === "uploaded" || foundFile.status === "processing") {
            setProcessingFileIds(prev => new Set([...prev, foundFile.id]));
          }
          
          // Show notification when file appears in table
          showNotification(`${foundFile.fileName} is being processed`, "info");
        }
      }
      
      // Remove found files from pending list (in reverse order to avoid index issues)
      pendingFilesFound.sort((a, b) => b.index - a.index);
      for (const {index} of pendingFilesFound) {
        newPendingFiles.splice(index, 1);
      }
      
      setPendingFiles(newPendingFiles);

      // Update processing IDs for existing files
      const newProcessingFileIds = new Set(processingFileIds);
      formattedFiles.forEach(file => {
        if (processingFileIds.has(file.id)) {
          if (file.status !== "uploaded" && file.status !== "processing") {
            newProcessingFileIds.delete(file.id);
            
            // Show notification if file is now ready for mapping
            if (file.status === "mapping_ready") {
              showNotification(`${file.fileName} is ready for mapping`, "success");
            } else if (file.status === "failed") {
              showNotification(`Processing failed for ${file.fileName}`, "error");
            }
          }
        }
      });
      
      setProcessingFileIds(newProcessingFileIds);
      setUploadedFiles(formattedFiles);
    } catch (error) {
      console.error("Error refreshing file list:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const showNotification = (message, severity = "info") => {
    setNotification({ message, severity });
  };

  const handleViewFile = (file) => {
    setViewingFile(file);
  };

  const handleReviewMapping = async (file) => {
    setProcessingFile(file);
    try {
      // No need to fetch mapping data here as it will be fetched in the MappingReview component
      setSelectedFile(file);
      setMappingReview(true);
      showNotification("Loading mapping review", "info");
    } catch (error) {
      showNotification("Error loading mapping review", "error");
      console.error("Error:", error);
    }
    setProcessingFile(null);
  };

  const handleBackToList = () => {
    setMappingReview(null);
    setSelectedFile(null);
    setViewingFile(null);

    // Reset search params, which might be modified by DataGrid on viewing page
    setSearchParams({});
  };

  const handleSaveMapping = async (mappings) => {
    try {
      showNotification("Mapping template saved successfully", "success");
    } catch (error) {
      showNotification("Error saving mapping template", "error");
      console.error("Error:", error);
    }
  };

  const handleApproveMapping = async (mappings) => {
    try {
      const updatedFiles = uploadedFiles.map((file) => (file.id === selectedFile.id ? { ...file, status: FileStatus.PROCESSING_COMPLETE } : file));
      setUploadedFiles(updatedFiles);
      showNotification("File processing started", "success");
      handleBackToList();
    } catch (error) {
      showNotification("Error processing file", "error");
      console.error("Error:", error);
    }
  };

  // Updated handleFileUpload function
  const handleFileUpload = async (file, schema) => {
    // Add to pending files
    setPendingFiles(prev => [...prev, { 
      fileName: file.name,
      object: schema.object,
      uploadStartTime: new Date()
    }]);
    
    const reader = new FileReader();

    reader.onload = async (e) => {
      const fileContents = e.target.result;
      const base64File = btoa(new Uint8Array(fileContents).reduce((data, byte) => data + String.fromCharCode(byte), ""));

      try {
        showNotification(`Uploading ${file.name}...`, "info");
        
        await authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          payload: {
            file_name: file.name,
            file_contents: base64File,
            target_object: schema.object,
          },
        });

        showNotification(`${file.name} uploaded successfully, processing in progress...`, "success");
        
        // Start polling to check when the file appears in the list
        refreshFileList();
      } catch (error) {
        // Remove from pending files on error
        setPendingFiles(prev => prev.filter(f => f.fileName !== file.name));
        
        showNotification(`Error uploading ${file.name}`, "error");
        console.error("Error:", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      minWidth: 400,
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {(processingFile?.id === params.row.id || 
            processingFileIds.has(params.row.id) || 
            params.row.status === "uploaded" || 
            params.row.status === "processing") && 
            <CircularProgress size={16} sx={{ mr: 1 }} />}
          {params.value}
        </Box>
      ),
    },

    { field: "object", headerName: "Object", minWidth: 200, flex: 1 },
    { field: "dateCreated", headerName: "Date Created", minWidth: 150, flex: 1 },
    { field: "owner", headerName: "Owner", minWidth: 150, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params.row.mappingConfidence && (
            <Typography variant='caption' sx={{ mr: 1 }}>
              ({Math.round(params.row.mappingConfidence * 100)}% match)
            </Typography>
          )}
          {params.value}
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: .5,
      getActions: (params) => [
        <GridActionsCellItem icon={<VisibilityIcon />} onClick={() => handleViewFile(params.row)} label='View' />,
        <GridActionsCellItem
          icon={<AssignmentIcon />}
          onClick={() => handleReviewMapping(params.row)}
          label='Review Mapping'
          showInMenu
          disabled={params.row.status !== FileStatus.MAPPING_READY}
        />,
        <GridActionsCellItem icon={<EnhanceIcon />} onClick={() => console.log("Enhance:", params.row)} label='Enhance' showInMenu />,
        <GridActionsCellItem icon={<CleanIcon />} onClick={() => console.log("Clean:", params.row)} label='Clean' showInMenu />,
        <GridActionsCellItem
          icon={<SyncIcon />}
          onClick={() => console.log("Sync:", params.row)}
          label='Sync'
          showInMenu
          disabled={params.row.status !== FileStatus.PROCESSING_COMPLETE}
        />,
      ],
    },
  ];

  // Show pending files in a modern, clean list above the main table
  const PendingFilesList = () => {
    if (pendingFiles.length === 0) return null;
    
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          mt: 2, 
          mb: 3, 
          p: 2, 
          border: '1px solid #e0e0e0', 
          borderRadius: 1,
          backgroundColor: '#f5f9ff'  // Light blue background
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Files being processed
        </Typography>
        
        {pendingFiles.map((file, index) => {
          // Calculate time elapsed since upload started
          const now = new Date();
          const elapsed = Math.floor((now - new Date(file.uploadStartTime)) / 1000);
          const timeDisplay = elapsed < 60 
            ? `${elapsed}s` 
            : `${Math.floor(elapsed / 60)}m ${elapsed % 60}s`;
            
          return (
            <Box 
              key={index} 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: index < pendingFiles.length - 1 ? 2 : 0,
                p: 1,
                borderRadius: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }}
            >
              <CircularProgress size={20} sx={{ mr: 2 }} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {file.fileName}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Target: {file.object} • Processing for {timeDisplay}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Paper>
    );
  };

  if (mappingReview && selectedFile) {
    return (
      <Box sx={{ pl: 3, pr: 3 }}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBackToList} sx={{ mb: 2 }}>
          Back to File List
        </Button>
        <MappingReview
          fileName={selectedFile.fileName}
          fileId={selectedFile.id}
          tenantId={tenantId}
          onSaveMapping={handleSaveMapping}
          onApproveMapping={handleApproveMapping}
        />
      </Box>
    );
  } else if (viewingFile) {
    return (
    <Box
      height='calc(100vh - 122px)' // absolute height required for MUI DataGrid to autosize
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleBackToList}
        sx={{ width: 'fit-content', mb: 2 }}
      >
        Back to File List
      </Button>
      <FileViewer fileName={viewingFile.fileName} fileId={viewingFile.id} tenantId={tenantId}/>
    </Box>
    )
  }

  return (
    <Box>
      <AlignedTitle></AlignedTitle>
      <Typography variant='body1' sx={{ mb: 3 }}>
      Ascent accelerates your data integration with AltviaOne through two powerful channels. First, connect your email systems (Gmail or Microsoft Exchange) to automatically extract and sync valuable business relationships - including accounts, contacts, and all related interactions. Second, use our streamlined file upload to rapidly import CSV data into any AltviaOne object, from investor records to fund data. Whether you're syncing email relationships or bulk loading structured data, Ascent provides the fastest path to getting your data working for you in AltviaOne.
      </Typography>

      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mt: 3, mb: 3 }}>
        <Tab label='Email Data Sync' />
        <Tab label='Uploaded Data' />
      </Tabs>

      {tabIndex === 0 && (
        <EmailSyncPanel user={user} showNotification={showNotification} />
      )}

      {tabIndex === 1 && (
        <>
          <AscentUpload onUpload={handleFileUpload} />
          
          {/* Show pending files that haven't appeared in the table yet */}
          <PendingFilesList />
          
          <DataGrid
            rows={uploadedFiles}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            sx={{ mt: 3, width: '100%' }}
            components={{
              MoreActionsIcon: MoreVertIcon,
            }}
          />
        </>
      )}

      {/* TODO: move this to a parent route and expose via context/provider */}
      <Snackbar open={!!notification} autoHideDuration={6000} onClose={() => setNotification(null)}>
        <Alert onClose={() => setNotification(null)} severity={notification?.severity || "info"} sx={{ width: "100%" }}>
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Ascent;