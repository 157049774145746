import { useState } from 'react';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Box,
  FormControlLabel,
  Chip,
  Stack
} from '@mui/material';

const AddOns = ({
  addOns,
  selectedTier,
  selectedAddOns,
  userCounts,
  enterpriseSeats,
  dataSources,
  onAddOnSelect,
  onUserCountChange,
  onDataSourcesChange
}) => {
  return (
    <Paper sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom>Add-Ons</Typography>
      
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '30%' }}>Add-On</TableCell>
              <TableCell sx={{ width: '40%' }}>Description</TableCell>
              <TableCell sx={{ width: '15%' }} align="center">Price</TableCell>
              <TableCell sx={{ width: '15%' }} align="center">Add</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(addOns).map(([addonId, addon]) => {
              // Skip any "email" add-on to ensure it doesn't appear
              if (addonId === 'email') return null;
              
              // Check if this add-on is available for the current tier
              const available = addon.availability[selectedTier];
              if (!available) return null;
              
              let price = '';
              let perUnit = '';
              
              if (addon.pricePerUser) {
                price = addon.pricePerUser[selectedTier];
                perUnit = 'per user';
              } else if (addonId === 'data') {
                price = addon.pricePerSource;
                perUnit = 'per source';
              } else if (addonId === 'enhancements') {
                price = addon.price[selectedTier].price;
                perUnit = `for ${addon.price[selectedTier].tokens} tokens`;
              }
              
              return (
                <TableRow key={addonId}>
                  <TableCell>
                    <Typography variant="body2" fontWeight="medium">{addon.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{addon.description}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Stack spacing={1} alignItems="center">
                      <Typography variant="body2">
                        ${price.toLocaleString()}
                      </Typography>
                      <Chip 
                        label={perUnit} 
                        size="small" 
                        variant="outlined" 
                        color="primary"
                      />
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedAddOns.includes(addonId)}
                            onChange={(e) => onAddOnSelect(addonId, e.target.checked)}
                            size="small"
                          />
                        }
                        label=""
                        sx={{ mx: 0 }}
                      />
                      
                      {selectedAddOns.includes(addonId) && (
                        <>
                          {addonId === 'data' ? (
                            <TextField
                              type="number"
                              size="small"
                              value={dataSources}
                              onChange={(e) => onDataSourcesChange(parseInt(e.target.value) || 0)}
                              InputProps={{ 
                                inputProps: { min: 0 },
                                sx: { textAlign: 'center' }
                              }}
                              sx={{ width: '80px' }}
                              placeholder="Sources"
                            />
                          ) : addon.pricePerUser ? (
                            <TextField
                              type="number"
                              size="small"
                              value={selectedTier === 'enterprise' ? enterpriseSeats : (userCounts[addonId] || '')}
                              onChange={(e) => onUserCountChange(addonId, parseInt(e.target.value) || 0)}
                              InputProps={{ 
                                inputProps: { min: 0 },
                                sx: { textAlign: 'center' }
                              }}
                              sx={{ width: '80px' }}
                              placeholder="Users"
                              disabled={selectedTier === 'enterprise'}
                            />
                          ) : null}
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AddOns;