import {
  Paper,
  Typography,
  Stack,
  Box,
  Divider,
  ButtonGroup,
  Button,
  Chip,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

// Import formatting utility
import { formatCurrency } from './utils/pricing-utils';

// Module-specific summary component
const ModuleSummaryItem = ({ 
  moduleId, 
  moduleData, 
  tier, 
  userCount, 
  includedCount, 
  excludeAIMe 
}) => {
  // Calculate appropriate price per user
  let pricePerUser;
  if (moduleId === 'fundraising') {
    pricePerUser = excludeAIMe && tier !== 'team' 
      ? moduleData.pricePerUser[tier].withoutAIMe 
      : moduleData.pricePerUser[tier].withAIMe;
  } else {
    pricePerUser = moduleData.pricePerUser[tier];
  }
  
  const effectiveCount = Math.max(0, userCount - includedCount);
  const moduleCost = effectiveCount * pricePerUser;
  
  return (
    <Box sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', p: 1, borderRadius: 1, mb: 1 }}>
      <Typography variant="body2" fontWeight="medium">{moduleData.name}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2 }}>
        <Typography variant="body2">Total Users:</Typography>
        <Typography variant="body2">{userCount}</Typography>
      </Box>
      {includedCount > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2 }}>
          <Typography variant="body2">Included Free:</Typography>
          <Typography variant="body2">{includedCount}</Typography>
        </Box>
      )}
      {includedCount > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2 }}>
          <Typography variant="body2">Billable Users:</Typography>
          <Typography variant="body2">{effectiveCount}</Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2 }}>
        <Typography variant="body2">Price Per User:</Typography>
        <Typography variant="body2">{formatCurrency(pricePerUser)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2 }}>
        <Typography variant="body2">Module Cost:</Typography>
        <Typography variant="body2" fontWeight="medium">{formatCurrency(moduleCost)}</Typography>
      </Box>
    </Box>
  );
};

const PricingSummary = ({
  selectedTier,
  selectedModules,
  selectedAddOns,
  moduleCosts,
  moduleCostsBeforeDiscount,
  discountAmount,
  addOnCosts,
  discountPercentage,
  yearTerm,
  onYearTermChange,
  totalCost,
  standardIncreaseRate,
  priceIncreaseRate,
  enterpriseSeats,
  perSeatPrice,
  excludeAIMe,
  includedLicenses,
  modulesData,
  userCounts,
  platformFee,
  licenseCosts
}) => {
  const annualCost = moduleCosts + addOnCosts;
  
  // Calculate average annual cost for multi-year with price increases
  const calculateAverageAnnualCost = () => {
    if (yearTerm === 1) return annualCost;
    
    let totalCost = annualCost;
    // Add cost for each subsequent year with the appropriate increase
    for (let year = 1; year < yearTerm; year++) {
      const yearCost = annualCost * Math.pow(1 + priceIncreaseRate/100, year);
      totalCost += yearCost;
    }
    
    return totalCost / yearTerm;
  };
  
  const averageAnnualCost = calculateAverageAnnualCost();

  return (
    <Paper 
      sx={{ 
        p: 3, 
        bgcolor: 'primary.light',
        position: 'relative',
        height: 'auto',
        maxHeight: 'none'
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textTransform: 'capitalize' }}>
        {selectedTier} Tier Summary
        {selectedTier !== 'team' && (
          <Chip 
            size="small" 
            color={excludeAIMe ? "default" : "secondary"}
            label={excludeAIMe ? "AIMe & Agents Excluded" : "Includes AIMe & Agents"}
            sx={{ ml: 1, verticalAlign: 'middle' }}
          />
        )}
      </Typography>

      <Stack spacing={2}>
        <ButtonGroup fullWidth size="small" sx={{ mb: 2 }}>
          {[1, 2, 3].map((years) => (
            <Button
              key={years}
              variant={yearTerm === years ? 'contained' : 'outlined'}
              onClick={() => onYearTermChange(years)}
            >
              {years} {years === 1 ? 'Year' : 'Years'}
            </Button>
          ))}
        </ButtonGroup>

        {selectedTier === 'enterprise' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Enterprise Seats:</Typography>
              <Typography fontWeight="medium">{enterpriseSeats}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Price Per Seat:</Typography>
              <Typography fontWeight="medium">{formatCurrency(perSeatPrice)}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Platform + Modules:</Typography>
              <Typography fontWeight="medium">
                {formatCurrency(moduleCosts)}
              </Typography>
            </Box>
          </>
        ) : (
          selectedModules.length > 0 && (
            <>
              {/* Platform Fee Section */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Platform Fee:</Typography>
                <Typography fontWeight="medium">
                  {formatCurrency(platformFee)}
                </Typography>
              </Box>
              {selectedTier !== 'team' && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, fontSize: '0.875rem' }}>
                  <Typography variant="body2" color="text.secondary">
                    AIMe & Agents {excludeAIMe ? "discount" : "included"}:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {excludeAIMe ? "-" : "+"}{formatCurrency(12000)}
                  </Typography>
                </Box>
              )}
              
              {/* Modules Section */}
              <Divider />
              <Typography variant="subtitle2">Module Details:</Typography>
              
              {/* Show each selected module */}
              {selectedModules.map(moduleId => (
                <ModuleSummaryItem
                  key={moduleId}
                  moduleId={moduleId}
                  moduleData={modulesData[moduleId]}
                  tier={selectedTier}
                  userCount={userCounts[moduleId] || 0}
                  includedCount={
                    includedLicenses[moduleId] && includedLicenses[moduleId][selectedTier] 
                      ? includedLicenses[moduleId][selectedTier] 
                      : 0
                  }
                  excludeAIMe={excludeAIMe}
                />
              ))}
              
              {/* Subtotal (before discount) */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Subtotal:</Typography>
                <Typography fontWeight="medium">
                  {formatCurrency(moduleCostsBeforeDiscount)}
                </Typography>
              </Box>
              
              {/* License Costs Display */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, fontSize: '0.875rem' }}>
                <Typography variant="body2" color="text.secondary">
                  Platform Fee:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatCurrency(platformFee)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, fontSize: '0.875rem' }}>
                <Typography variant="body2" color="text.secondary">
                  License Costs:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatCurrency(licenseCosts)}
                </Typography>
              </Box>
              
              {/* Volume Discount */}
              {selectedModules.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>License Discount:</Typography>
                    <Tooltip title={`${Math.round(discountPercentage)}% discount applies only to license costs (${formatCurrency(licenseCosts)})`}>
                      <InfoIcon fontSize="small" sx={{ ml: 0.5, color: 'info.main', fontSize: '0.875rem' }} />
                    </Tooltip>
                  </Box>
                  <Typography fontWeight="medium" color="success.main">
                    -{Math.round(discountPercentage)}% ({formatCurrency(discountAmount)})
                  </Typography>
                </Box>
              )}
              
              {/* Module Subtotal (after discount) */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Module Subtotal:</Typography>
                <Typography fontWeight="medium">
                  {formatCurrency(moduleCosts)}
                </Typography>
              </Box>
            </>
          )
        )}

        {/* Add-ons Section */}
        {selectedAddOns.length > 0 && (
          <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Add-ons:</Typography>
              <Typography fontWeight="medium">{selectedAddOns.length}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Add-ons Subtotal:</Typography>
              <Typography fontWeight="medium">
                {formatCurrency(addOnCosts)}
              </Typography>
            </Box>
          </>
        )}

        {/* Totals Section */}
        {(selectedModules.length > 0 || selectedTier === 'enterprise') && (
          <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Annual Total:</Typography>
              <Typography variant="subtitle1" fontWeight="medium">
                {formatCurrency(annualCost)}
              </Typography>
            </Box>

            {yearTerm > 1 && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Term Length:</Typography>
                  <Typography fontWeight="medium">{yearTerm} Years</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Standard Annual Increase:</Typography>
                  <Typography fontWeight="medium">{Math.round(standardIncreaseRate)}%</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Your Annual Increase:</Typography>
                  <Typography fontWeight="medium" color="success.main">
                    {Math.round(priceIncreaseRate)}%
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Average Annual Cost:</Typography>
                  <Typography fontWeight="medium">
                    {formatCurrency(averageAnnualCost)}
                  </Typography>
                </Box>
              </>
            )}

            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">
                {yearTerm > 1 ? `${yearTerm}-Year Total:` : 'Total:'}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {formatCurrency(totalCost)}
              </Typography>
            </Box>
            {yearTerm > 1 && (
              <Typography variant="body2" color="text.secondary" align="right">
                (avg. {formatCurrency(averageAnnualCost)} per year)
              </Typography>
            )}
          </>
        )}

        <Typography variant="caption" align="center" color="text.secondary">
          *Includes platform fee, {selectedTier === 'enterprise' ? 'all modules' : 'selected modules'}, and add-ons
        </Typography>
      </Stack>
    </Paper>
  );
};

export default PricingSummary;