/**
 * Gets the enterprise user price based on the number of seats
 * @param {number} seats - Number of enterprise seats
 * @returns {number} - Price per user
 */
export const getEnterpriseUserPrice = (seats) => {
  if (seats < 25) return 4000;
  if (seats < 50) return 4000;
  if (seats < 100) return 3500;
  if (seats < 150) return 3000;
  if (seats < 200) return 2100;
  if (seats < 250) return 1800;
  return 1500;
};

/**
 * Gets the volume discount based on number of modules selected
 * @param {number} moduleCount - Number of modules selected
 * @param {string} tier - Current tier (enterprise has no volume discount)
 * @returns {number} - Discount multiplier (e.g., 0.95 for 5% discount)
 */
export const getVolumeDiscount = (moduleCount, tier) => {
  if (tier === 'enterprise') return 1; // No volume discount for enterprise
  switch(moduleCount) {
    case 2: return 0.95; // 5% off
    case 3: return 0.90; // 10% off
    case 4: return 0.85; // 15% off
    default: return 1;
  }
};

/**
 * Gets the price increase rate based on term length
 * @param {number} years - Term length in years
 * @returns {number} - Price increase rate per year (decimal)
 */
export const getPriceIncreaseRate = (years) => {
  switch(years) {
    case 2: return 0.05; // 5% increase per year instead of 7%
    case 3: return 0.04; // 4% increase per year instead of 7%
    default: return 0.07; // Standard 7% increase
  }
};

/**
 * Formats a number as USD currency
 * @param {number} amount - Amount to format
 * @returns {string} - Formatted currency string
 */
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

/**
 * Gets the correct price per user based on module, tier, and AIMe settings
 * @param {string} moduleId - Module identifier
 * @param {string} tier - Selected tier
 * @param {boolean} excludeAIMe - Whether AIMe is excluded
 * @param {object} modulesData - Modules data object
 * @returns {number} - Price per user
 */
export const getCorrectPricePerUser = (moduleId, tier, excludeAIMe, modulesData) => {
  if (moduleId === 'fundraising') {
    return excludeAIMe && tier !== 'team'
      ? modulesData[moduleId].pricePerUser[tier].withoutAIMe
      : modulesData[moduleId].pricePerUser[tier].withAIMe;
  }
  return modulesData[moduleId].pricePerUser[tier];
};