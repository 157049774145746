import { Typography } from "@mui/material";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";

const AlignedTitle = () => {
  return (
    <Typography
      variant='h4'
      sx={{
        mb: 3,
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        "& svg": {
          fontSize: "1.4em",
          position: "relative",
          top: "1px",
          marginLeft: "2px",
        },
      }}>
      <LandscapeOutlinedIcon />
      Ascent
    </Typography>
  );
};

export default AlignedTitle;
