import { Outlet, useLoaderData } from "react-router"
import { Box } from "@mui/material";
import BottomMenu from "./BottomMenu";
import { authedApiFetch } from "../utils/Api";
import { useState, useEffect } from "react";
import useMediaQuery from "../hooks/useMediaQuery";

export const rootLoader = async () => {
  const tenants = await authedApiFetch({ endpoint: "/tenants", method: 'GET'});

  // fetch and refresh stored user
  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  const user = await authedApiFetch({ endpoint: `/users/${storedUser.id}`, method: 'GET'});
  sessionStorage.setItem('user', JSON.stringify(user));

  return { tenants, user };
}

const Root = () => {
  const data = useLoaderData();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const isLaptop = useMediaQuery('(max-width: 1200px)');

  // Sync with localStorage on mount and when media query changes
  useEffect(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    if (savedState !== null) {
      setIsSidebarCollapsed(JSON.parse(savedState));
    } else {
      setIsSidebarCollapsed(isLaptop);
      localStorage.setItem('sidebarCollapsed', JSON.stringify(isLaptop));
    }
  }, [isLaptop]);

  return (
    <Box sx={{ 
      display: "flex", 
      flex: 1, 
      overflow: "hidden", 
      pb: "74px",
    }}>
      <Outlet context={data}/>
      <BottomMenu user={data.user} tenants={data.tenants} />
    </Box>
  )
};

export default Root;