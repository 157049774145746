// EmailSyncConfigModal.jsx
import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  Divider,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { authedApiFetch } from "../../utils/Api";
import { useRevalidator } from "react-router";

const ExclusionSection = ({ title, type, items, handleDelete }) => {
  const lowerCased = title.toLowerCase();

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="subtitle2" gutterBottom>{title}</Typography>
      {items.length === 0 ? (
        <Typography variant="body2" color="text.secondary">No {lowerCased}</Typography>
      ) : (
        <List dense>
          {items.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                item.user_id ?
                <IconButton 
                  edge="end" 
                  aria-label="delete"
                  onClick={() => handleDelete(type, item.id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
                :
                <span>Tenant Exclusion</span>
              }
            >
              <ListItemText primary={item.value} />
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  )
}

const EmailSyncConfigModal = ({ user, open, onClose, showNotification }) => {
  // React Router hook to force revalidation of state
  const revalidator = useRevalidator();

  // Controlled form state for the modal
  const [exclusionType, setExclusionType] = useState('domain');
  const [exclusionValue, setExclusionValue] = useState('');
  
  // Exclusions by type
  const [domainExclusions, setDomainExclusions] = useState([]);
  const [senderTypeExclusions, setSenderTypeExclusions] = useState([]);
  const [specificEmailExclusions, setspecificEmailExclusions] = useState([]);

  const fetchExclusions = async () => {
    const exclusionsResponse = await authedApiFetch({
      endpoint: '/email_exclusions',
      method: 'GET'
    })

    const grouped = Object.groupBy(exclusionsResponse, (e) => e.exclusion_type )

    grouped.domain && setDomainExclusions(grouped.domain);
    grouped.sender_type && setSenderTypeExclusions(grouped.sender_type);
    grouped.specific_email && setspecificEmailExclusions(grouped.specific_email);
  };

  useEffect(() => {
    open && fetchExclusions();
  },[open])

  // Format and memoize last sync time using international formatter
  const lastRunFormattedDate = useMemo(() => {
    const lastSyncDate = new Date(user.ms_last_sync);
    const formatter = new Intl.DateTimeFormat('en-US', {
      dateStyle: "long",
      timeStyle: "short",
    });
    return formatter.format(lastSyncDate);
  }, [user]);

  const handleAutoSyncChange = async (event) => {
    const newValue = event.target.checked;

    try {
      await authedApiFetch({
        endpoint: `/users/${user.id}`,
        method: 'PATCH',
        payload: {
          user: {
            ms_auto_sync: newValue
          }
        }
      })

      // imperatively revalidate b/c not using React Router action.
      // TODO: refactor so that this goes through a clientAction, to save calls.
      revalidator.revalidate();

      showNotification(`Automatic sync ${newValue ? 'enabled' : 'disabled'}`, "success");
    } catch (e) {
      console.error('Failed to save auto sync setting due to error:', e.message);
      showNotification(`Failed to ${newValue ? 'enable' : 'disable'} automatic sync`, "error");
    }
  };

  const handleAddExclusion = async () => {
    const value = exclusionValue.trim();
    if (!value) return;

    try {
      const newExclusion = await authedApiFetch({
        endpoint: '/email_exclusions',
        method: 'POST',
        payload: {
          exclusion_type: exclusionType,
          value: exclusionValue
        }
      })
  
      // Optimistically add to UI
      if (exclusionType === 'domain') {
        setDomainExclusions([...domainExclusions, newExclusion]);
      } else if (exclusionType === 'sender_type') {
        setSenderTypeExclusions([...senderTypeExclusions, newExclusion]);
      } else if (exclusionType === 'specific_email') {
        setspecificEmailExclusions([...specificEmailExclusions, newExclusion]);
      }
      
      setExclusionValue('');
      showNotification("Exclusion saved successfully", "success");
    } catch (e) {
      console.error('Failed to save exclusion due to error:', e.message);
      showNotification("Failed to save exclusion", "error");
    }
  };
  
  const handleDeleteExclusion = async (type, id) => {
    try {
      await authedApiFetch({
        endpoint: `/email_exclusions/${id}`,
        method: 'DELETE'
      });

      // Optimistically remove from UI
      if (type === 'domain') {
        setDomainExclusions(domainExclusions.filter((e) => e.id != id));
      } else if (type === 'sender_type') {
        setSenderTypeExclusions(senderTypeExclusions.filter((e) => e.id != id));
      } else if (type === 'specific_email') {
        setspecificEmailExclusions(specificEmailExclusions.filter((e) => e.id != id));
      }

      showNotification("Exclusion removed successfully", "success");
    } catch (e) {
      console.error('Failed to remove exclusion due to error:', e.message);
      showNotification("Failed to remove exclusion", "error");
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Email Sync Configuration</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>Sync Settings</Typography>
          <FormControlLabel
            control={
              <Switch 
                checked={user.ms_auto_sync} 
                onChange={handleAutoSyncChange}
                color="primary"
              />
            }
            label="Enable Automatic Sync"
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            When enabled, email data will be automatically synced every 24 hours.
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Last sync: <strong>{lastRunFormattedDate}</strong>
          </Typography>
        </Box>
        
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="subtitle1" gutterBottom>Exclusion Lists</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Emails matching these exclusions will not be synced to AltviaOne.
        </Typography>
        
        <Box sx={{ display: 'flex', mb: 2, alignItems: 'flex-start' }}>
          <FormControl sx={{ minWidth: 200, mr: 2 }}>
            <InputLabel id="exclusion-type-label">Exclusion Type</InputLabel>
            <Select
              labelId="exclusion-type-label"
              value={exclusionType}
              label="Exclusion Type"
              onChange={(e) => setExclusionType(e.target.value)}
            >
              <MenuItem value="domain">Domain</MenuItem>
              <MenuItem value="sender_type">Sender Type</MenuItem>
              <MenuItem value="specific_email">Specific Email</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            label={
              exclusionType === 'domain' ? 'Domain (e.g., example.com)' : 
              exclusionType === 'sender_type' ? 'Sender Type (e.g., no-reply)' :
              'Email Address'
            }
            value={exclusionValue}
            onChange={(e) => setExclusionValue(e.target.value)}
            sx={{ flexGrow: 1, mr: 1 }}
          />
          
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={handleAddExclusion}
            sx={{ mt: 1 }}
          >
            Add
          </Button>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ExclusionSection
            title="Domain Exclusions"
            type="domain"
            items={domainExclusions}
            handleDelete={handleDeleteExclusion}
          />

          <ExclusionSection
            title="Sender Type Exclusions"
            type="sender_type"
            items={senderTypeExclusions}
            handleDelete={handleDeleteExclusion}
          />

          <ExclusionSection
            title="Specific Email Exclusions"
            type="specific_email"
            items={specificEmailExclusions}
            handleDelete={handleDeleteExclusion}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailSyncConfigModal;