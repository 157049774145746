import { useLoaderData } from "react-router";
import { apiFetch } from "../../utils/Api";
import { Authenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import '@aws-amplify/ui-react/styles.css';
import { useEffect } from "react";
import { Amplify } from "aws-amplify";


export const loader = async ({ request }) => {
  // extracting search params
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const sfUserId = searchParams.get("id");

  // Use SF user ID to retrieve Cognito login config for the user's tenant
  const response = await apiFetch({
    endpoint: '/auth/aime_config',
    method: 'GET',
    params: { id: sfUserId }
  })

  return response.config;
}

// Utility component to wrap post-login behavior
const TokenReporter = () => {
  useEffect(() => {
    const reportToken = async () => {
      const authSession = await fetchAuthSession();
      const idToken = authSession.tokens.idToken.toString();
    
      // TODO: restrict URL to the base domain of the tenant's sf org (get from DB)
      window.opener.postMessage(idToken,'*')
    
      // Close window (assumed to be popup)
      window.close();
    };

    reportToken();
  },[])

  return (<>Returning to SalesForce. This window will close automatically</>);
}

// Cognito login experience that can be embedded in or opened by a Salesforce LWC.
const SalesforceLogin = () => {
  const authConfig = useLoaderData();

  if (authConfig) {
    // Configure Amplify before rendering Authenticator
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: authConfig.userPoolId,
          userPoolClientId: authConfig.clientId
        }
      }
    });

    return (
      // Center everything
      <div style={{
        display:"flex",
        justifyContent:'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}>
        <Authenticator.Provider>
          <Authenticator hideSignUp>
            <TokenReporter />
          </Authenticator>
        </Authenticator.Provider>
      </div>
    )
  } else {
    return (<div>An error occurred.</div>)
  }
}

export default SalesforceLogin;