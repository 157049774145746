import { createTheme } from '@mui/material/styles';

const altviaTheme = createTheme({
  palette: {
    primary: {
      main: '#8C8EFF', // New purple accent color
      light: '#B0B1FF',
      dark: '#6B6DCF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#67A9CF',
      light: '#98C8E1',
      dark: '#4B89AF',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FAFBFF', // Subtle purple tint to white
      paper: '#FFFFFF',
      lightPurple: '#F7F7FF', // Very light purple for subtle backgrounds
      lightBlue: '#F2F6F8', // Light blue background (lighter version of E4EBEF)
    },
    text: {
      primary: '#212330', // Darker, more readable text
      secondary: '#4A4B68', // Darker secondary text for better contrast
      tertiary: '#767792', // Darker tertiary text
    },
    accent: {
      purple: '#8C8EFF', // Main accent
      teal: '#4EC5C1', // Complementary accent
      coral: '#FF7F92', // For highlights and alerts
    },
    divider: 'rgba(140, 142, 255, 0.12)', // Subtle purple divider
  },
  typography: {
    fontFamily: '"Onest", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      letterSpacing: '-0.01562em',
      color: '#2A2B40',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
      letterSpacing: '-0.00833em',
      color: '#2A2B40',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      letterSpacing: '0em',
      color: '#2A2B40',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
      letterSpacing: '0.00735em',
      color: '#2A2B40',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.4rem',
      letterSpacing: '0em',
      color: '#2A2B40',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.25rem',
      letterSpacing: '0.0075em',
      color: '#2A2B40',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0.00938em',
      color: '#5A5B78',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0.00714em',
      color: '#5A5B78',
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.00938em',
      color: '#2A2B40',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: '0.01071em',
      color: '#2A2B40',
      lineHeight: 1.43,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
      letterSpacing: '0.02857em',
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: '0.03333em',
      color: '#8B8CA8',
    },
  },
  shape: {
    borderRadius: 12,
  },
  shadows: [
    'none',
    '0px 2px 8px rgba(140, 142, 255, 0.07)',
    '0px 4px 16px rgba(140, 142, 255, 0.1)',
    // ...default shadows for the rest
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          padding: '10px 24px',
          textTransform: 'none',
          fontWeight: 600,
          transition: 'all 0.2s ease-in-out',
          boxShadow: 'none',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 20px rgba(140, 142, 255, 0.25)',
          },
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #8C8EFF 30%, #A09FFF 90%)',
          color: '#FFFFFF',
          '&:hover': {
            background: 'linear-gradient(45deg, #7B7DFF 30%, #8F8EFF 90%)',
          },
        },
        outlinedPrimary: {
          borderColor: '#8C8EFF',
          color: '#8C8EFF',
          '&:hover': {
            backgroundColor: 'rgba(140, 142, 255, 0.08)',
          },
        },
        textPrimary: {
          color: '#8C8EFF',
          '&:hover': {
            backgroundColor: 'rgba(140, 142, 255, 0.08)',
          },
        },
        sizeLarge: {
          padding: '12px 28px',
          fontSize: '1.05rem',
        },
        sizeSmall: {
          padding: '6px 16px',
          fontSize: '0.85rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 8px 24px rgba(140, 142, 255, 0.12)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          overflow: 'hidden',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 30px rgba(140, 142, 255, 0.16)',
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: 'cover',
          transition: 'transform 0.5s ease',
          '&:hover': {
            transform: 'scale(1.03)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 700,
          marginBottom: '12px',
        },
        subtitle1: {
          marginBottom: '12px',
          color: '#5A5B78',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          marginBottom: 4,
          transition: 'all 0.2s ease',
          '&.Mui-selected': {
            backgroundColor: 'rgba(140, 142, 255, 0.12)',
            color: '#212330', // Dark gray, almost black text
            '&:hover': {
              backgroundColor: 'rgba(140, 142, 255, 0.2)',
            },
            '& .MuiListItemIcon-root': {
              color: '#8C8EFF', // Keep the icon purple for visual indication
            },
            '& .MuiListItemText-root': {
              '& .MuiTypography-root': {
                color: '#212330', // Dark gray, almost black text
                fontWeight: 600,
              },
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(140, 142, 255, 0.05)',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: 600,
          borderRadius: 12,
          padding: '0 8px',
          height: 20,
          minWidth: 20,
          fontSize: '0.75rem',
        },
        colorPrimary: {
          backgroundColor: '#8C8EFF',
          color: '#FFFFFF',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#212330',
          boxShadow: '0 2px 12px rgba(140, 142, 255, 0.1)',
          borderRadius: 0, // Ensure app bar has no rounded corners
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          borderRight: '1px solid rgba(140, 142, 255, 0.12)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        indicator: {
          height: 3,
          borderRadius: 1.5,
          backgroundColor: '#8C8EFF',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '0.9rem',
          minWidth: 100,
          '&.Mui-selected': {
            color: '#212330', // Dark gray, almost black text
            fontWeight: 700,
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '3px',
              backgroundColor: '#8C8EFF', // Purple indicator underneath
            }
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#8C8EFF',
              borderWidth: 2,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#8C8EFF',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#8C8EFF',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
        elevation1: {
          boxShadow: '0 2px 10px rgba(140, 142, 255, 0.08)',
        },
        elevation2: {
          boxShadow: '0 4px 20px rgba(140, 142, 255, 0.1)',
        },
        // Special case for footer - no rounded corners
        footer: {
          borderRadius: 0,
        },
      },
      variants: [
        {
          props: { variant: 'footer' },
          style: {
            borderRadius: 0,
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 12
        },
        switchBase: {
          padding: 1,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#FFFFFF',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#8C8EFF',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          opacity: 1,
          backgroundColor: '#E9E9E9',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
          fontSize: '0.85rem',
        },
        colorPrimary: {
          backgroundColor: 'rgba(140, 142, 255, 0.12)',
          color: '#212330', // Dark text for better readability
          border: '1px solid rgba(140, 142, 255, 0.5)', // Light purple border
        },
        colorSuccess: {
          backgroundColor: 'rgba(76, 175, 80, 0.12)',
          color: '#2E7D32', // Dark green text
          border: '1px solid rgba(76, 175, 80, 0.5)',
        },
        colorError: {
          backgroundColor: 'rgba(211, 47, 47, 0.12)',
          color: '#C62828', // Dark red text
          border: '1px solid rgba(211, 47, 47, 0.5)',
        },
        colorWarning: {
          backgroundColor: 'rgba(255, 152, 0, 0.12)',
          color: '#D84315', // Dark orange text
          border: '1px solid rgba(255, 152, 0, 0.5)',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#F7F7FF',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(even)': {
              backgroundColor: '#FCFCFF',
            },
            '&:hover': {
              backgroundColor: 'rgba(140, 142, 255, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(140, 142, 255, 0.08)',
              color: '#212330',
              '&:hover': {
                backgroundColor: 'rgba(140, 142, 255, 0.12)',
              },
            },
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        },
        columnHeader: {
          color: '#212330',
          fontWeight: 600,
        },
      },
    },
  },
});

export default altviaTheme;