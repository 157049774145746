import React from "react";
import { Navigate, createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import UDM from "./features/UDM/UDM";
import Ascent, { ascentLoader } from "./features/Ascent/Ascent";
import Launchpad, { dashboardLoader } from "./features/Dashboard/Dashboard";
import UserList, { loader as usersLoader } from "./features/Users/UserList";
import Login from "./components/Login";
import AuthCallback from "./components/AuthCallback";
import Root, { rootLoader } from "./components/Root";
import theme from './styles/theme';
import ErrorPage from "./components/ErrorPage";
import QSEmbeddedDashboard from "./features/AnswersEmbeddedAuthoring/QuicksightEmbeddedAuthoring";
import DataTables, { loader as dataTablesLoader } from './features/DataTables/DataTables'
import DataIndex from './features/DataTables/DataIndex'
import DataTable, { loader as dataTableLoader } from './features/DataTables/DataTable';
import DataTableRecords, { loader as dataTableRecordsLoader } from './features/DataTables/DataTableRecords';
import DataTableSettings, { action as dataTableSettingsAction } from './features/DataTables/DataTableSettings';
import DataTableIndex from './features/DataTables/DataTableIndex';
import DataTableNew, { action as dataTableNewAction } from './features/DataTables/DataTableNew';
import { approvalAction, recordUpdateAction, getPresignedUrlAction } from './features/DataTables/DataGridDrawer'
import Connections, { loader as connectionsLoader, action as createConnectionAction } from './features/Connections/Connections'
import ConnectionDetails, { updateAction as connectionUpdateAction } from './features/Connections/ConnectionDetails';
import ConnectionIndex from './features/Connections/ConnectionIndex'
import Tenant, { tenantLoader } from "./components/Tenant";
import RootIndex from "./components/RootIndex";
import Pricing from "./features/Pricing/Pricing";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msal";
import TenantAdmin from "./features/TenantAdmin/TenantAdmin";
import SalesforceLogin, { loader as salesforceLoginLoader } from "./features/SalesforceLogin/SalesforceLogin";

// higher-order function used for consistent login redirect logic across loaders
import { loaderWrapper } from "./utils/Api";
import { createLoaderDebug } from './loaderDebug';

const wrapLoader = (loader, name) => {
  const debugLoader = process.env.NODE_ENV === 'development' 
    ? createLoaderDebug(loader, name) 
    : loader;
  return loaderWrapper(debugLoader);
};

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/auth/callback',
      element: <AuthCallback />,
    },
    {
      path: '/sf/login',
      loader: salesforceLoginLoader,
      element: <SalesforceLogin/>
    },
    {
      path: '/',
      element: <Root />,
      loader: wrapLoader(rootLoader, 'rootLoader'),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <RootIndex />
        },
        {
          path: 'tenants/:tenantId',
          element: <Tenant />,
          loader: wrapLoader(tenantLoader, 'tenantLoader'),
          children: [
            {
              index: true,
              path: 'dashboard',
              element: <Launchpad />,
              loader: wrapLoader(dashboardLoader, 'dashboardLoader')
            },
            {
              path: 'pricing',
              element: <Pricing />
            },
            {
              path: 'udm',
              element: <UDM />
            },
            {
              path: 'ascent',
              element: <Ascent />,
              loader: wrapLoader(ascentLoader, 'ascentLoader')
            },
            {
              path: 'users',
              element: <UserList />,
              loader: wrapLoader(usersLoader, 'usersLoader')
            },
            {
              path: 'answersDashboard',
              element: <QSEmbeddedDashboard />
            },
            {
              path: "connections",
              element: <Connections />,
              loader: wrapLoader(connectionsLoader, 'connectionsLoader'),
              action: createConnectionAction,
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: <ConnectionIndex />
                },
                {
                  path: ":connectionId",
                  element: <ConnectionDetails />,
                  children: [
                    {
                      path: "update",
                      action: connectionUpdateAction
                    }
                  ]
                }
              ]
            },
            {
              path: "data-tables",
              element: <DataTables />,
              loader: wrapLoader(dataTablesLoader, 'dataTablesLoader'),
              errorElement: <ErrorPage />,
              children: [
                {
                  index: true,
                  element: <DataIndex />
                },
                {
                  path: "new",
                  element: <DataTableNew />,
                  action: dataTableNewAction
                },
                {
                  path: ":dataTableId",
                  element: <DataTable />,
                  loader: wrapLoader(dataTableLoader, 'dataTableLoader'),
                  children: [
                    {
                      index: true,
                      element: <DataTableIndex />
                    },
                    {
                      path: "records",
                      element: <DataTableRecords />,
                      loader: wrapLoader(dataTableRecordsLoader, 'dataTableRecordsLoader'),
                      children: [
                        {
                          path: ":recordId/update",
                          action: recordUpdateAction
                        },
                        {
                          path: ":recordId/approve",
                          action: approvalAction
                        },
                        {
                          path: ":recordId/presigned_url",
                          action: getPresignedUrlAction
                        }
                      ]
                    },
                    {
                      path: "settings",
                      element: <DataTableSettings />,
                      action: dataTableSettingsAction
                    }
                  ]
                }
              ]
            },
            {
              path: "admin",
              element: <TenantAdmin />
            },
            {
              path: '*',
              element: <Navigate to='dashboard' />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to='/' />
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <MsalProvider instance={msalInstance}>
          <RouterProvider router={router} />
        </MsalProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;