// Sidebar.jsx
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router";
import { Drawer, List, ListItemText, Box, Typography, Card, IconButton, Badge, ListItemIcon, ListItemButton, Tooltip } from "@mui/material";
import {
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  TableView as TableViewIcon,
  Cable as CableIcon,
  SchemaOutlined as SchemaOutlinedIcon,
  LandscapeOutlined as LandscapeOutlinedIcon,
  StorefrontOutlined as StorefrontOutlinedIcon,
  Calculate as CalculateIcon,
  Settings as SettingsIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "../../hooks/useMediaQuery";

// Import the modular components
import ContactsSection from "./ContactsSection";
import AccountDetails from "./AccountDetails";

/**
 * Sidebar component for the application
 * @param {Object} tenant - The tenant object containing information and contact users
 * @param {Object} user - The current user object
 */
const Sidebar = ({ tenant, user }) => {
  const theme = useTheme();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Check if screen is laptop size or smaller (1200px is a common breakpoint for laptops)
  const isLaptop = useMediaQuery("(max-width: 1200px)");

  // Load saved preference from localStorage or default to collapsed on laptops
  useEffect(() => {
    const savedState = localStorage.getItem("sidebarCollapsed");
    if (savedState !== null) {
      setIsSidebarCollapsed(JSON.parse(savedState));
    } else {
      setIsSidebarCollapsed(isLaptop);
    }
  }, [isLaptop]);

  // Save preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isSidebarCollapsed));
    // Dispatch custom event to notify other components
    window.dispatchEvent(new Event("sidebarStateChange"));
  }, [isSidebarCollapsed]);

  const { tenantId } = useParams();

  const menuItems = () => {
    // All users
    let items = [
      {
        name: "Marketplace",
        icon: <StorefrontOutlinedIcon />,
        component: "dashboard",
        path: `/tenants/${tenantId}/dashboard`,
      },
    ];

    // only Altvia users
    if (!user.tenant_id) {
      items.push(
        {
          name: "Pricing",
          icon: <CalculateIcon />,
          component: "pricing",
          path: `/tenants/${tenantId}/pricing`,
        },
        {
          name: "Universal Data Model",
          icon: <SchemaOutlinedIcon />,
          component: "udm",
          path: `/tenants/${tenantId}/udm`,
        }
      );
    }

    // Only tenant admins
    if (user.role === "tenant_admin") {
      items.push({
        name: "Ascent",
        icon: <LandscapeOutlinedIcon />,
        component: "ascent",
        path: `/tenants/${tenantId}/ascent`,
      });
    }

    // only Altvia users
    if (!user.tenant_id) {
      items.push({
        name: "Connections",
        icon: <CableIcon />,
        component: "connections",
        path: `/tenants/${tenantId}/connections`,
      });
    }

    // Available to all users
    items = items.concat([
      {
        name: "Data Tables",
        icon: <TableViewIcon />,
        component: "data-tables",
        path: `/tenants/${tenantId}/data-tables`,
      },
      {
        name: "Users",
        icon: <PeopleAltOutlinedIcon />,
        component: "users",
        path: `/tenants/${tenantId}/users`,
      },
    ]);

    // Only Altvia admin users
    if (user.role === "altvia_admin") {
      items.push({
        name: "Tenant Admin",
        icon: <SettingsIcon />,
        component: "admin",
        path: `/tenants/${tenantId}/admin`,
      });
    }

    return items;
  };

  // custom hook to determine currently selected item based on location (URI)
  const useLocationToFindSelectedItem = () => {
    const { pathname } = useLocation();
    const menuItemsOutput = menuItems();
    const selectedItem = menuItemsOutput.find((item) => pathname.includes(item.path));

    return selectedItem.component;
  };

  const selected = useLocationToFindSelectedItem();

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // Calculate drawer width based on collapsed state
  const drawerWidth = isSidebarCollapsed ? 80 : 320;

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#F7F7FF",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "70px",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
        },
      }}>
      {/* Fixed Logo Section with Toggle Button */}
      <Card
        sx={{
          borderRadius: 0,
          paddingBottom: 0,
          display: "flex",
          justifyContent: isSidebarCollapsed ? "center" : "space-between",
          alignItems: "center",
          padding: isSidebarCollapsed ? 1 : 2,
        }}>
        {isSidebarCollapsed ? (
          <IconButton onClick={toggleSidebar} size='large'>
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
              {tenant.logo_url ? (
                <img src={tenant.logo_url} alt='Customer Logo' style={{ maxWidth: "100%", height: "80px", objectFit: "contain" }} />
              ) : (
                <Typography variant='h5'>{tenant.name}</Typography>
              )}
            </Box>
            <IconButton onClick={toggleSidebar} size='small' sx={{ ml: 1 }}>
              <ChevronLeftIcon />
            </IconButton>
          </>
        )}
      </Card>

      {/* Scrollable Content Area */}
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {/* Navigation Menu */}
        <List>
          {menuItems().map((item) => (
            <Tooltip title={isSidebarCollapsed ? item.name : ""} placement='right' key={`tooltip-${item.component}`}>
              <ListItemButton
                key={item.component}
                selected={selected === item.component}
                component={Link}
                to={item.path}
                sx={{
                  justifyContent: isSidebarCollapsed ? "center" : "flex-start",
                  py: isSidebarCollapsed ? 1.5 : 1,
                  minHeight: 48,
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& .MuiListItemIcon-root": {
                      color: theme.palette.primary.contrastText,
                    },
                    "& .MuiListItemText-primary": {
                      color: theme.palette.primary.contrastText,
                    },
                  },
                  "& .MuiListItemIcon-root": {
                    color: selected === item.component ? theme.palette.primary.contrastText : theme.palette.primary.main,
                    minWidth: isSidebarCollapsed ? 0 : 40,
                    mr: isSidebarCollapsed ? 0 : 2,
                  },
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {!isSidebarCollapsed && (
                  <ListItemText
                    primary={item.name}
                    sx={{
                      color: selected === item.component ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    }}
                  />
                )}
                {!isSidebarCollapsed && item.badge > 0 && <Badge badgeContent={item.badge} color='error' />}
              </ListItemButton>
            </Tooltip>
          ))}
        </List>

        {/* Contact Cards - Only show when sidebar is expanded */}
        {!isSidebarCollapsed && <ContactsSection contacts={tenant} />}
      </Box>

      {/* Fixed Account Details Footer */}
      <AccountDetails tenant={tenant} user={user} isCollapsed={isSidebarCollapsed} onToggleSidebar={toggleSidebar} />
    </Drawer>
  );
};

export default Sidebar;
