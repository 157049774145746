import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { authedApiFetch } from "../../utils/Api";
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { useMsal } from "@azure/msal-react";
import { useRevalidator } from "react-router";

const MSAuth = ({ user }) => {
  // React Router hook to force refresh of data from parent routes
  const revalidator = useRevalidator();

  // Hook to access MS auth library instance (MSAL) from MsalProvider. See:
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook
  const { instance, accounts } = useMsal();

  // Scope passed to MSAL login and auth token request (contains our app ID)
  const authScope = `api://${process.env.REACT_APP_MS_CLIENT_ID}/.default`;

  // Take follow-up actions after user has logged in via MSAL popup
  useEffect(() => {
    const getToken = async () => {
      // requests to get an access token
      const request = {
        scopes: [authScope],
        account: accounts[0],
        extraQueryParameters: {
          prompt: "consent"
        }
      };

      try {
        // gets the token with permissions from the popup
        const tokenResponse = await instance.acquireTokenSilent(request);
        const tenantId = tokenResponse.tenantId
        const accessToken = tokenResponse.accessToken;

        // gets on-behalf-of token, saves to user, starts email import
        await authedApiFetch({
          endpoint: `/ms_auth`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          payload: {
            ms_access_token: accessToken,
            ms_tenant_id: tenantId
          }
        });

        // Imperatively revalidate b/c not using React Router action.
        // Refreshes all data on page and parent pages, to get updated user.
        // TODO: refactor so that this goes through a clientAction.
        revalidator.revalidate();
      } catch (error) {
        console.error("Failed to acquire token:", error);
      }
    }

    if (accounts && accounts.length > 0 && !user.ms_authorized) {
      getToken();
    }
  },[accounts])

  // Handles making the request to login to Microsoft and consent to our app
  const handleLogin = async () => {
    try {
      const loginRequest = {
        scopes: [authScope],
        extraQueryParameters: {
          prompt: "consent"
        }
      };

      // open Microsoft login popup via instance exposed by MSAL hook
      await instance.loginPopup(loginRequest);
    } catch (error) {
      console.error("Login failed:", error);
    }
  }

  return (
    <Button
      variant="contained"
      startIcon={<MicrosoftIcon />}
      onClick={handleLogin}
      disabled={user.ms_authorized}
      sx={{
        mr: 3,
        "&.Mui-disabled": {
          backgroundColor: "#1a73e8",
          color: "#fff",
          border: "1px solid #1a73e8",
        },
      }}>
      {user.ms_authorized ? "Outlook Connected" : "Connect Outlook"}
    </Button>
  )
}

export default MSAuth