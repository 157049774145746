import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box, Typography, Alert, Snackbar } from "@mui/material";
import SchemaSelector from './SchemaSelector';

const AscentUpload = ({ onUpload }) => {
  const [selectedSchema, setSelectedSchema] = React.useState(null);
  const [error, setError] = React.useState(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (!selectedSchema) return;
    
    // Check if there are any rejected files
    if (rejectedFiles && rejectedFiles.length > 0) {
      // Handle the first rejected file's error
      const firstRejection = rejectedFiles[0];
      if (firstRejection.errors) {
        const errorMessages = firstRejection.errors.map(err => err.message).join(", ");
        setError(errorMessages);
      }
      return;
    }
    
    acceptedFiles.forEach((file) => {
      onUpload(file, selectedSchema);
    });
  }, [onUpload, selectedSchema]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    disabled: !selectedSchema,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv']
    },
    maxSize: 2 * 1024 * 1024 * 1024, // 2GB in bytes
    multiple: false,
    onDropRejected: (rejectedFiles) => {
      const rejection = rejectedFiles[0];
      if (rejection.errors) {
        // Format a user-friendly error message
        if (rejection.errors.some(e => e.code === 'file-too-large')) {
          setError('File is too large. Maximum file size is 2GB.');
        } else if (rejection.errors.some(e => e.code === 'file-invalid-type')) {
          setError('Invalid file type. Only CSV files are accepted.');
        } else {
          setError('File upload failed: ' + rejection.errors.map(e => e.message).join(', '));
        }
      }
    }
  });

  // Close the error snackbar
  const handleCloseError = () => {
    setError(null);
  };

  return (
    <div>
      <SchemaSelector onSchemaSelect={setSelectedSchema} />
      
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #cccccc",
          borderRadius: "4px",
          padding: "40px",
          textAlign: "center",
          cursor: selectedSchema ? "pointer" : "not-allowed",
          opacity: selectedSchema ? 1 : 0.5,
          transition: "background-color 0.3s, border-color 0.3s",
          "&:hover": selectedSchema ? {
            backgroundColor: "rgba(0,0,0,0.04)",
            borderColor: "primary.main"
          } : {},
          ...(isDragActive && {
            backgroundColor: "primary.light",
            borderColor: "primary.main",
          })
        }}
      >
        <input {...getInputProps()} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CloudUploadIcon fontSize="large" color="primary" />
          
          <Typography variant="h6">
            {!selectedSchema 
              ? "Please select a target object first"
              : isDragActive 
                ? "Drop files here..."
                : "Drag files here or click to select"
            }
          </Typography>
          <Typography variant="caption">Only CSVs are supported at this time with a maximum limit of 2GB</Typography>
        </Box>
      </Box>
      
      {/* Error notification */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AscentUpload;