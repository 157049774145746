// utils/stringUtils.js
export const toTitleCase = (str) => {
    if (typeof str !== 'string') {
      return ''; // or return str if you want to keep the original value
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  

/**
 * Formats a phone number string.
 * @param {string} phoneNumber - The phone number to format, e.g., "5129391920".
 * @returns {string} The formatted phone number, e.g., "(512) 939-1920".
 */
export const formatPhoneNumber = (phoneNumber) => {
  // Ensure input is a string and has exactly 10 digits
  if (typeof phoneNumber !== 'string' || phoneNumber.length !== 10) {
    return phoneNumber; // return the input if it's not in the expected format
  }

  // Extract the area code, the first three digits, and the last four digits
  const areaCode = phoneNumber.slice(0, 3);
  const centralOfficeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6);

  // Format the number in the desired format
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

/**
 * Gets the URL for a user's photo based on their name.
 * @param {Object} user - The user object containing first_name and last_name.
 * @returns {string} The URL to the user's photo.
 */
export const getUserPhotoUrl = (user) => {
  if (!user || !user.first_name || !user.last_name) {
    return '/assets/images/user_photos/default_avatar.png';
  }
  
  // Create the filename using the naming convention: firstname_lastname.png (all lowercase)
  const firstName = user.first_name.toLowerCase();
  const lastName = user.last_name.toLowerCase();
  const photoName = `${firstName}_${lastName}.png`;
  
  return `/assets/images/user_photos/${photoName}`;
};
