// SchemaSelector.jsx
import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Box 
} from '@mui/material';
import { authedApiFetch } from "../../utils/Api";

const SchemaSelector = ({ onSchemaSelect }) => {
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState('');
  
  useEffect(() => {
    const fetchSchemas = async () => {
      try {
        const response = await authedApiFetch({
          endpoint: '/target_schemas',
          method: 'GET'
        });
        setSchemas(response);
      } catch (error) {
        console.error('Error fetching schemas:', error);
      }
    };

    fetchSchemas();
  }, []);

  return (
    <Box sx={{ mb: 3 }}>
      <FormControl sx={{ width: '25ch' }} >
        <InputLabel id="target-object-label">Target Object</InputLabel>
        <Select
          labelId="target-object-label"
          id="target-object-select"
          value={selectedSchema}
          label="Target Object"
          onChange={(e) => {
            const value = e.target.value;
            setSelectedSchema(value);
            const schema = schemas.find(s => s.id === value);
            onSchemaSelect(schema);
          }}
        >
          <MenuItem value="">
            <em>Select a target object...</em>
          </MenuItem>
          {schemas.map((schema) => (
            <MenuItem key={schema.id} value={schema.id}>
              {schema.object}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SchemaSelector;