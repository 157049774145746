// EmailSyncPanel.jsx
import React, { useState } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import SyncIcon from '@mui/icons-material/Sync';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import MSAuth from "./MSAuth";
import EmailDataTables from "./EmailDataTables";
import EmailSyncConfigModal from "./EmailSyncConfigModal";

import { LoadingButton } from "@mui/lab";
import { authedApiFetch } from "../../utils/Api";


const EmailSyncPanel = ({ user, showNotification }) => {
  const [syncConfigOpen, setSyncConfigOpen] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);

  const handleOpenSyncConfig = () => {
    setSyncConfigOpen(true);
  };
  
  const handleCloseSyncConfig = () => {
    setSyncConfigOpen(false);
  };

  const handleStartIngestion = async () => {
    setSyncLoading(true);

    try {
      await authedApiFetch({
        endpoint: '/ascent/email/ingestions',
        method: 'POST'
      })

      showNotification("Succesfully started email data extraction", "success");
    } catch (e) {
      showNotification("Failed to start email data extraction", "error");
    }

    setSyncLoading(false);
  }

  const handleStartSync = async () => {

    try {
      await authedApiFetch({
        endpoint: '/ascent/email/syncs',
        method: 'POST'
      })

      showNotification("Succesfully started sync to SF", "success");
    } catch (e) {
      showNotification("Failed to start sync to SF", "error");
    }
  };

  return (
    <>
      <Paper elevation={0} sx={{ p: 3, mb: 3, backgroundColor: '#F7F7FF', border: '1px solid #e0e0e0' }}>
        <Typography variant="h6" gutterBottom>Email Connection</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <MSAuth user={user} />
          <Button
            variant='contained'
            startIcon={<GoogleIcon />}
            sx={{ mr: 2 }}
            disabled
          >
            Connect Gmail - Planned Feature
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary">
          Connect your email accounts to automatically extract business relationships and interactions.
        </Typography>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Email Data</Typography>
        <Box sx={{ display: 'flex', columnGap: 1 }}>
          <LoadingButton 
            variant='outlined' 
            startIcon={<ForwardToInboxIcon />}
            onClick={handleStartIngestion}
            loading={syncLoading}
          >
            Extract Email Data
          </LoadingButton>
          <Button 
            variant='outlined' 
            startIcon={<SyncIcon />}
            onClick={handleStartSync}
          >
            Sync to Salesforce
          </Button>
          <Button 
            variant='outlined' 
            startIcon={<SettingsSuggestOutlinedIcon />}
            onClick={handleOpenSyncConfig}
          >
            Configure Sync
          </Button>
        </Box>
      </Box>
      
      <EmailDataTables />
      
      <EmailSyncConfigModal
        user={user}
        open={syncConfigOpen}
        onClose={handleCloseSyncConfig}
        showNotification={showNotification}
      />
    </>
  );
};

export default EmailSyncPanel;