// ContactCard.jsx
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { formatPhoneNumber, getUserPhotoUrl } from "../../utils/utils";

/**
 * ContactCard component displays a user card with their information
 * @param {Object} user - The user object containing first_name, last_name, email, and phone
 * @param {string} role - The role of the user (e.g., "CSM", "TAM")
 */
const ContactCard = ({ user, role }) => {
  const theme = useTheme();
  
  return (
    <Card variant='outlined' sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      <Avatar
        sx={{ width: 56, height: 56, ml: 1 }}
        src={getUserPhotoUrl(user)}
        alt={`${user.first_name} ${user.last_name}`}
      />
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography variant='subtitle2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
          {user.first_name} {user.last_name}
        </Typography>
        <Typography variant='body2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
          {role}
        </Typography>
        <Typography variant='body2'>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </Typography>
        <Typography variant='body2'>{formatPhoneNumber(user.phone)}</Typography>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
