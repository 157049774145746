import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * A generic modal component for displaying additional information about features
 * 
 * @param {boolean} open - Whether the modal is open
 * @param {function} onClose - Function to call when the modal is closed
 * @param {object} feature - The feature object containing information to display
 */
const FeatureInfoModal = ({ open, onClose, feature }) => {
  if (!feature) return null;

  return (
    <Modal 
      open={open} 
      onClose={onClose}
      aria-labelledby="feature-info-modal-title"
    >
      <Box sx={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: 600 },
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        overflow: 'auto'
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography id="feature-info-modal-title" variant="h5" component="h2">
            {feature.name}
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        
        {feature.moreInfo && (
          <Box>
            {typeof feature.moreInfo === 'string' ? (
              <Typography variant="body1">{feature.moreInfo}</Typography>
            ) : (
              // If moreInfo is a React component or complex object
              feature.moreInfo
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default FeatureInfoModal;
