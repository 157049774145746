// EmailDataTables.jsx
import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { authedApiFetch } from "../../utils/Api";
import { useSearchParams } from "react-router";
import DataGrid from "../DataTables/DataGrid";

const EmailDataTableTab = ({ type, title }) => {
  const [tableData, setTableData] = useState();
  const [rowCount, setRowCount] = useState();

  // React Router hook to set & react to query params, which DataGrid sets
  const [searchParams, _setSearchParams] = useSearchParams();

  // TODO: when we have time, refactor this to use a Router loader
  useEffect(() => {
    async function loadRecords() {
      try {
        const recordsPromise = authedApiFetch({
          endpoint: '/ascent/email/records',
          method: "GET",
          params: {
            type,
            per: searchParams.get("per") || 5,
            page: searchParams.get("page") || 0,
            sort_column: searchParams.get("sort_column") || 'id',
            sort_descending: searchParams.get("sort_order") || false
          }
        });

        const rowCountPromise = authedApiFetch({
          endpoint: '/ascent/email/records/count',
          method: 'GET',
          params: { type }
        })

        // Await all in parallel
        const [recordsData, rowCountData] = await Promise.all([recordsPromise, rowCountPromise]);

        // set table data (contains headers, rows, and parameters)
        setTableData(recordsData);

        // set row count
        setRowCount(rowCountData);
      } catch (error) {
        console.error('Error in loader:', error);
        throw error;
      }
    };

    loadRecords();
  }, [searchParams]);

  // At the moment, the tables will always be read-only
  const tableMetadata ={
    name: title,
    display_document: false,
    hide_id_column: true,
    editable: false,
    autosize: true
  }

  if (!tableData && !rowCount) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      <DataGrid
        dataTable={tableMetadata}
        parameters={tableData.parameters}
        rows={tableData.rows}
        headers={tableData.headers}
        rowCount={rowCount}
      />
    </Box>
  );
}

const EmailDataTables = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (_event, newValue) => {
    // Change tab and reset table-related search params in one go,
    // otherwise params carry over between tables and error on different columns
    setSearchParams(new URLSearchParams({tab: newValue}), { replace: true });
  };

  const activeTab = parseInt(searchParams.get("tab")) || 0

  return (
    <>
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label='Accounts' />
        <Tab label='Contacts' />
        <Tab label='Interactions' />
        <Tab label='Interaction References' />
      </Tabs>
      
      {activeTab === 0 && (
        <EmailDataTableTab type="account" title="Accounts"/>
      )}
      
      {activeTab === 1 && (
        <EmailDataTableTab type="contact" title="Contacts"/>
      )}
      
      {activeTab === 2 && (
        <EmailDataTableTab type="interactions" title="Interactions"/>
      )}
  
      {activeTab === 3 && (
        <EmailDataTableTab type="interaction_references" title="Interaction References"/>
      )}
    </>
  );
};

export default EmailDataTables;