import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_CLIENT_ID,
    authority: process.env.REACT_APP_MS_AUTHORITY,
    redirectUri: process.env.REACT_APP_MS_REDIRECT_URL,
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);