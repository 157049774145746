// ContactsSection.jsx
import React from "react";
import { Box, Divider } from "@mui/material";
import ContactCard from "./ContactCard";

/**
 * ContactsSection component displays the section of contact cards for the various roles
 * @param {Object} contacts - Object containing the user objects for different roles (csm_user, tam_user, etc.)
 */
const ContactsSection = ({ contacts }) => {
  const { csm_user, tam_user, exec_sponsor_user, project_manager_user } = contacts;
  
  // If there are no contacts, don't render anything
  if (!csm_user && !tam_user && !exec_sponsor_user && !project_manager_user) {
    return null;
  }
  
  return (
    <>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ p: 1 }}>
        {csm_user && <ContactCard user={csm_user} role="CSM" />}
        {tam_user && <ContactCard user={tam_user} role="TAM" />}
        {exec_sponsor_user && <ContactCard user={exec_sponsor_user} role="Executive Sponsor" />}
        {project_manager_user && <ContactCard user={project_manager_user} role="Project Manager" />}
      </Box>
    </>
  );
};

export default ContactsSection;
