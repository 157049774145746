// FileViewer.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DataGrid from "../DataTables/DataGrid";
import { authedApiFetch } from "../../utils/Api";
import { useSearchParams } from "react-router";

const FileViewer = ({ fileName, fileId, tenantId }) => {
  const [tableData, setTableData] = useState();
  const [rowCount, setRowCount] = useState();

  // React Router hook to set & react to query params, which DataGrid sets
  const [searchParams, _setSearchParams] = useSearchParams();

  // TODO: when we have time, refactor this to use a Router loader
  useEffect(() => {
    async function loadRecords() {
      try {
        const recordsPromise = authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files/${fileId}/show_records`,
          method: "GET",
          params: {
            per: searchParams.get("per") || 25,
            page: searchParams.get("page") || 0,
            sort_column: searchParams.get("sort_column") || "record_id",
            sort_descending: searchParams.get("sort_order") || false
          }
        });

        const rowCountPromise = authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files/${fileId}/count_records`,
          method: 'GET'
        })

        // Await all in parallel
        const [recordsData, rowCountData] = await Promise.all([recordsPromise, rowCountPromise]);

        // set table data (contains headers, rows, and parameters)
        setTableData(recordsData);

        // set row count
        setRowCount(rowCountData);
      } catch (error) {
        console.error('Error in loader:', error);
        throw error;
      }
    };

    loadRecords();
  }, [searchParams]);

  // At the moment, the table metadata will always be the same
  const tableMetadata ={
    name: "File View",
    display_document: false,
    editable: false,
  }

  if (!tableData && !rowCount) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
      }}
    >
      <Typography variant="h5">
        Viewing records for file: {fileName}
      </Typography>
      <DataGrid
        dataTable={tableMetadata}
        parameters={tableData.parameters}
        rows={tableData.rows}
        headers={tableData.headers}
        rowCount={rowCount}
      />
    </Box>
  );
};

export default FileViewer;