import { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Stack,
  IconButton,
  Collapse,
  Alert,
  Chip,
  Tooltip
} from '@mui/material';
import { Check, Remove, ExpandMore, ExpandLess, Info, HelpOutline } from '@mui/icons-material';
import FeatureInfoModal from './FeatureInfoModal';

const columnWidths = {
  features: '40%',
  tier: '20%',
  business: '25%',
  users: '15%'
};

const ModulesTable = ({ 
  modules, 
  selectedTier, 
  selectedModules, 
  userCounts,
  enterpriseSeats,
  excludeAIMe,
  onModuleSelect, 
  onUserCountChange,
  onEnterpriseSeatsChange,
  onTierChange,
  onExcludeAIMeChange,
  includedLicenses
}) => {
  const [expandedModules, setExpandedModules] = useState(new Set());
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const toggleModule = (moduleId) => {
    const newExpanded = new Set(expandedModules);
    if (newExpanded.has(moduleId)) {
      newExpanded.delete(moduleId);
    } else {
      newExpanded.add(moduleId);
    }
    setExpandedModules(newExpanded);
  };

  const handleOpenModal = (feature, event) => {
    event.stopPropagation(); // Prevent triggering other click handlers
    setSelectedFeature(feature);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFeature(null);
  };

  const renderFeatureValue = (value, aimeRequired) => {
    if (value === undefined) return null;

    // For category headers
    if (typeof value === 'boolean' && value === true) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        {<Check color="success" />}
      </div>;
    }
    if (value === false) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        {<Remove color="disabled" />}
      </div>;
    }
    if (value === 'None') {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Remove color="disabled" />
      </div>;
    }
    
    // For add-on or special values
    if (typeof value === 'string') {
      let color;
      if (value === 'Add-On') {
        color = 'warning';
      } else {
        color = 'info';
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Chip 
            label={value} 
            size="small" 
            color={color} 
            variant="outlined"
          />
        </div>
      );
    }
    
    return <div style={{ textAlign: 'center' }}>
      {value === true ? <Check color="success" /> : value}
      {aimeRequired && excludeAIMe && selectedTier !== 'team' && (
        <Tooltip title="Requires AIMe (currently excluded)">
          <Info fontSize="small" color="warning" sx={{ ml: 1, verticalAlign: 'middle' }} />
        </Tooltip>
      )}
    </div>;
  };

  const commonCellStyles = {
    width: columnWidths.tier,
    padding: '8px 16px',
    borderRight: '1px solid rgba(224, 224, 224, 1)'
  };
  
  const tierCellStyles = (tier) => ({
    ...commonCellStyles,
    width: tier === 'business' ? columnWidths.business : columnWidths.tier,
    cursor: 'pointer',
    bgcolor: selectedTier === tier ? 'primary.light' : tier === 'business' ? 'rgba(25, 118, 210, 0.04)' : 'inherit',
    borderBottom: selectedTier === tier ? '2px solid' : 'inherit',
    borderBottomColor: 'primary.main',
    transition: 'all 0.2s',
    '&:hover': {
      bgcolor: selectedTier === tier ? 'primary.light' : 'grey.100'
    },
    '& .MuiTypography-root': {
      fontWeight: selectedTier === tier ? 'bold' : 'normal'
    }
  });

  const getModuleIncludedLicenses = (moduleId) => {
    if (includedLicenses[moduleId] && includedLicenses[moduleId][selectedTier]) {
      return includedLicenses[moduleId][selectedTier];
    }
    return 0;
  };
  
  return (
    <Paper sx={{ mb: 4, p: 3 }}>
      <Typography variant="h6" gutterBottom>Core Modules</Typography>
      <TableContainer>
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ 
                  width: columnWidths.features,
                  borderRight: '1px solid rgba(224, 224, 224, 1)'
                }}
              >
                Features
              </TableCell>
              {['team', 'business', 'enterprise'].map((tier) => (
                <TableCell 
                  key={tier}
                  align="center"
                  onClick={() => onTierChange(tier)}
                  sx={tierCellStyles(tier)}
                >
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    {tier}
                    {tier !== 'team' && (
                      <Typography variant="caption" display="block" sx={{ fontWeight: 'normal' }}>
                        (includes AIMe & Agents)
                      </Typography>
                    )}
                  </Typography>
                </TableCell>
              ))}
              <TableCell 
                align="center" 
                sx={{ 
                  width: columnWidths.users,
                  padding: '8px'
                }}
              >
                Total Users
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTier === 'enterprise' && (
              <TableRow>
                <TableCell colSpan={4} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  <Alert severity="info" sx={{ mb: 1 }}>
                    Enterprise tier includes all modules with unified user licensing
                  </Alert>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    size="small"
                    value={enterpriseSeats}
                    onChange={(e) => {
                      const value = parseInt(e.target.value) || 0;
                      onEnterpriseSeatsChange(value);
                    }}
                    InputProps={{ 
                      inputProps: { min: 25 },
                      sx: { textAlign: 'center' }
                    }}
                    sx={{ width: '80px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {selectedTier !== 'team' && (
              <TableRow>
                <TableCell colSpan={4} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={excludeAIMe}
                        onChange={(e) => onExcludeAIMeChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Exclude AIMe and Agents"
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
            {Object.entries(modules).map(([moduleId, module]) => [
              <TableRow key={moduleId} sx={{ bgcolor: 'grey.50' }}>
                <TableCell 
                  colSpan={4} 
                  sx={{ 
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    padding: '8px 16px'
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                      size="small"
                      onClick={() => toggleModule(moduleId)}
                    >
                      {expandedModules.has(moduleId) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModules.includes(moduleId)}
                          onChange={(e) => onModuleSelect(moduleId, e.target.checked)}
                          disabled={selectedTier === 'enterprise'}
                        />
                      }
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>{module.name}</Typography>
                          {moduleId === 'fundraising' && includedLicenses[moduleId] && (
                          <Chip 
                              size="small" 
                              color="primary" 
                              label={`${getModuleIncludedLicenses(moduleId)} included`} 
                              variant="outlined"
                              sx={{ whiteSpace: 'nowrap', minWidth: '100px' }}
                            />
                          )}
                        </Stack>
                      }
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {selectedModules.includes(moduleId) && selectedTier !== 'enterprise' && (
                    <TextField
                      type="number"
                      size="small"
                      value={userCounts[moduleId] || ''}
                      onChange={(e) => onUserCountChange(moduleId, parseInt(e.target.value) || 0)}
                      InputProps={{ 
                        inputProps: { min: 0 },
                        sx: { textAlign: 'center' }
                      }}
                      sx={{ width: '100px' }}
                      // FormHelperTextProps={{
                      //   sx: { 
                      //     whiteSpace: 'nowrap',
                      //     top: '8px'
                      //   }
                      // }}
                      // helperText={
                      //   moduleId === 'fundraising' && includedLicenses[moduleId] ? 
                      //     `(${getModuleIncludedLicenses(moduleId)} included)` : 
                      //     ''
                      // }
                    />
                  )}
                </TableCell>
              </TableRow>,
              <TableRow key={`${moduleId}-features`}>
                <TableCell colSpan={5} sx={{ padding: 0, border: 0 }}>
                  <Collapse in={expandedModules.has(moduleId)}>
                    <Table size="small" sx={{ tableLayout: 'fixed' }}>
                      <TableBody>
                        {module.features.map((feature, idx) => (
                          <TableRow 
                            key={`${moduleId}-${idx}`}
                            sx={feature.category ? { bgcolor: 'grey.100' } : null}
                          >
                            <TableCell 
                              sx={{ 
                                width: columnWidths.features,
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                padding: '8px 16px',
                                paddingLeft: feature.category ? '8px' : '8px',
                                fontWeight: feature.category ? 'bold' : 'normal'
                              }}
                            >
                              {feature.name}
                              {feature.moreInfo && (
                                <Tooltip title="More information">
                                  <IconButton 
                                    size="small" 
                                    onClick={(e) => handleOpenModal(feature, e)}
                                    sx={{ ml: 1 }}
                                  >
                                    <HelpOutline fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={commonCellStyles}
                            >
                              {!feature.category && renderFeatureValue(feature.team, feature.aimeRequired)}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={{
                                ...commonCellStyles,
                                width: columnWidths.business,
                                bgcolor: selectedTier === 'business' ? 'primary.light' : 'rgba(25, 118, 210, 0.04)'
                              }}
                            >
                              {!feature.category && renderFeatureValue(
                                feature.business, 
                                feature.aimeRequired && excludeAIMe
                              )}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={commonCellStyles}
                            >
                              {!feature.category && renderFeatureValue(
                                feature.enterprise,
                                feature.aimeRequired && excludeAIMe
                              )}
                            </TableCell>
                            <TableCell sx={{ width: columnWidths.users }} />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            ])}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Feature Info Modal */}
      <FeatureInfoModal 
        open={modalOpen} 
        onClose={handleCloseModal} 
        feature={selectedFeature} 
      />
    </Paper>
  );
};

export default ModulesTable;