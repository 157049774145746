import { Box, ThemeProvider, createTheme } from "@mui/material";
import PricingCalculator from "./PricingCalculator";
import PricingTitle from "./PricingTitle";

function Pricing() {
  return (
    <ThemeProvider 
      theme={(theme) => 
        createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              light: "#E4EBEF",
              main: theme.palette.primary.main || "#2196f3"
            },
            secondary: {
              ...theme.palette.secondary,
              main: theme.palette.secondary.main || "#f50057"
            }
          },
          // Explicitly keep typography from parent theme
          typography: {
            ...theme.typography
          }
        })
      }
    >
      <Box maxWidth='xl' sx={{ py: 0 }}>
        <PricingTitle />
        <PricingCalculator />
      </Box>
    </ThemeProvider>
  );
}

export default Pricing;