// AccountDetails.jsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  Collapse,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ContentCopy as ContentCopyIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { toTitleCase } from "../../utils/utils";

/**
 * AccountDetails component shows tenant account information in the sidebar footer
 * @param {Object} tenant - The tenant object containing account details
 * @param {Object} user - The current user object
 * @param {boolean} isCollapsed - Whether the sidebar is in collapsed state
 * @param {Function} onToggleSidebar - Function to toggle sidebar collapse state
 */
const AccountDetails = ({ tenant, user, isCollapsed, onToggleSidebar }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };
  
  const handleCopyShortName = async () => {
    await navigator.clipboard.writeText(tenant.short_name);
    console.log("Copied short name to clipboard");
  };
  
  // Render simplified version when sidebar is collapsed
  if (isCollapsed) {
    return (
      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          display: "flex",
          justifyContent: "center",
          p: 1,
        }}>
        <Tooltip title='Account Details'>
          <IconButton size='small' onClick={onToggleSidebar}>
            <InfoIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
  
  // Render full version when sidebar is expanded
  return (
    <Box
      sx={{
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      }}>
      <Box display='flex' justifyContent='space-between' alignItems='center' px={2} pt={1}>
        <Box display='flex' alignItems='center'>
          <InfoIcon fontSize='small' sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant='subtitle2' color='text.secondary'>
            Account Details
          </Typography>
        </Box>
        <IconButton size='small' onClick={toggleCollapse}>
          {isExpanded ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box px={2} pb={1.5}>
          <Typography variant='body2' color='text.secondary' gutterBottom>
            <strong>CAB Participant:</strong> {tenant.cab_participant ? "Yes" : "No"}
          </Typography>
          <Typography variant='body2' color='text.secondary' gutterBottom>
            <strong>Care Support:</strong> {toTitleCase(tenant.care_support)}
          </Typography>
          <Typography variant='body2' color='text.secondary' gutterBottom>
            <strong>Became Client:</strong> {new Date(tenant.became_client_on).toLocaleDateString("en-US")}
          </Typography>
          {!user.tenant_id && (
            <>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <Typography variant='body2' color='text.secondary' gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                <strong>Short Name:</strong>
                <Box component='span' sx={{ ml: 0.5 }}>
                  {tenant.short_name}
                </Box>
                <IconButton size='small' onClick={handleCopyShortName} sx={{ ml: 0.5, p: 0 }}>
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Typography>
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default AccountDetails;
