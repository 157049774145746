import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ModulesTable from './ModulesTable';
import AddOns from './Addons';
import PricingSummary from './PricingSummary';

// Import pricing data from JSON files
import platformPricing from './data/platform-pricing.json';
import modulesData from './data/modules-data.json';
import addOnsData from './data/addons-data.json';

// Import utility functions
import { 
  getEnterpriseUserPrice, 
  getVolumeDiscount, 
  getPriceIncreaseRate, 
  getCorrectPricePerUser 
} from './utils/pricing-utils';

const PricingCalculator = () => {
  const [selectedTier, setSelectedTier] = useState('business');
  const [previousTier, setPreviousTier] = useState('business');
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [userCounts, setUserCounts] = useState({});
  const [enterpriseSeats, setEnterpriseSeats] = useState(25);
  const [dataSources, setDataSources] = useState(0);
  const [yearTerm, setYearTerm] = useState(1);
  const [excludeAIMe, setExcludeAIMe] = useState(false);

  // Destructure platform pricing from imported JSON
  const { basePricingWithAIMe, basePricingWithoutAIMe, includedLicenses } = platformPricing;

  useEffect(() => {
    if (selectedTier === 'enterprise') {
      setSelectedModules(Object.keys(modulesData));
      setEnterpriseSeats(10);
    } else if (previousTier === 'enterprise') {
      // Only clear selections when coming FROM enterprise
      setSelectedModules([]);
      // Removed: setUserCounts({}); - to fix the user count persistence issue
    }
    // Otherwise, keep selections and counts when switching between team/business
  }, [selectedTier]);

  // Calculate license costs only (no platform fee)
  const calculateLicenseCosts = () => {
    let licenseCosts = 0;
    
    if (selectedTier === 'enterprise') {
      const perSeatPrice = getEnterpriseUserPrice(enterpriseSeats);
      licenseCosts = enterpriseSeats * perSeatPrice;
      return licenseCosts;
    }

    selectedModules.forEach(moduleId => {
      const moduleUserCount = userCounts[moduleId] || 0;
      let effectiveUserCount = moduleUserCount;
      
      // Check if we need to subtract included licenses for any module
      if (includedLicenses[moduleId] && includedLicenses[moduleId][selectedTier] > 0) {
        effectiveUserCount = Math.max(0, moduleUserCount - includedLicenses[moduleId][selectedTier]);
      }
      
      // Get the correct price based on AIMe inclusion
      const pricePerUser = getCorrectPricePerUser(moduleId, selectedTier, excludeAIMe, modulesData);
      licenseCosts += effectiveUserCount * pricePerUser;
    });

    return licenseCosts;
  };
  
  // Get platform fee
  const getPlatformFee = () => {
    const basePricing = excludeAIMe && selectedTier !== 'team' ? 
      basePricingWithoutAIMe : basePricingWithAIMe;
    
    return basePricing[selectedTier];
  };

  // Calculate module costs before volume discount
  const calculateModuleCostsBeforeDiscount = () => {
    return getPlatformFee() + calculateLicenseCosts();
  };

  // Calculate the discount amount explicitly
  const calculateDiscountAmount = () => {
    if (selectedModules.length <= 1 || selectedTier === 'enterprise') {
      return 0;
    }
    
    // Get the license costs (without platform fee)
    const licenseCosts = calculateLicenseCosts();
    
    // Calculate discount based on the volume discount utility function
    const discountMultiplier = getVolumeDiscount(selectedModules.length, selectedTier);
    const discountAmount = licenseCosts * (1 - discountMultiplier);
    
    // Apply discount to license costs only
    return discountAmount;
  };

  // Get the raw discount percentage (based on module count)
  const getRawDiscountPercentage = () => {
    if (selectedModules.length <= 1 || selectedTier === 'enterprise') {
      return 0;
    }
    
    // Get the discount percentage from the utility function
    const discountMultiplier = getVolumeDiscount(selectedModules.length, selectedTier);
    return (1 - discountMultiplier) * 100;
  };

  // Calculate module costs with discount applied
  const calculateModuleCosts = () => {
    const beforeDiscount = calculateModuleCostsBeforeDiscount();
    const discount = calculateDiscountAmount();
    
    // Subtract the discount from the subtotal
    return beforeDiscount - discount;
  };

  const calculateAddOnCosts = () => {
    let total = 0;
    selectedAddOns.forEach(addonId => {
      const addon = addOnsData[addonId];
      if (addon.pricePerUser) {
        const userCount = selectedTier === 'enterprise' ? enterpriseSeats : (userCounts[addonId] || 0);
        total += userCount * addon.pricePerUser[selectedTier];
      } else if (addonId === 'data') {
        total += dataSources * addon.pricePerSource;
      } else if (addonId === 'enhancements') {
        total += addon.price[selectedTier].price;
      }
    });
    return total;
  };

  const calculateTotalCost = () => {
    const firstYearCost = calculateModuleCosts() + calculateAddOnCosts();
    
    // For single year, just return the cost
    if (yearTerm === 1) {
      return firstYearCost;
    }
    
    // For multi-year, calculate with annual price increases
    const priceIncreaseRate = getPriceIncreaseRate(yearTerm);
    let totalCost = firstYearCost;
    
    // Add cost for each subsequent year with the appropriate increase
    for (let year = 1; year < yearTerm; year++) {
      const yearCost = firstYearCost * Math.pow(1 + priceIncreaseRate, year);
      totalCost += yearCost;
    }
    
    return totalCost;
  };

  const handleModuleSelect = (moduleId, checked) => {
    if (selectedTier === 'enterprise') return;
    
    setSelectedModules(prev =>
      checked
        ? [...prev, moduleId]
        : prev.filter(m => m !== moduleId)
    );
    if (!checked) {
      setUserCounts(prev => {
        const newCounts = { ...prev };
        delete newCounts[moduleId];
        return newCounts;
      });
    }
  };

  const handleAddOnSelect = (addonId, checked) => {
    setSelectedAddOns(prev =>
      checked
        ? [...prev, addonId]
        : prev.filter(a => a !== addonId)
    );
    if (!checked) {
      setUserCounts(prev => {
        const newCounts = { ...prev };
        delete newCounts[addonId];
        return newCounts;
      });
    }
  };

  const handleUserCountChange = (id, count) => {
    setUserCounts(prev => ({
      ...prev,
      [id]: count
    }));
  };

  const handleTierChange = (newTier) => {
    setPreviousTier(selectedTier);
    setSelectedTier(newTier);
  };

  const handleExcludeAIMeChange = (exclude) => {
    setExcludeAIMe(exclude);
  };

  const getIncludedLicenses = (moduleId) => {
    if (includedLicenses[moduleId] && includedLicenses[moduleId][selectedTier]) {
      return includedLicenses[moduleId][selectedTier];
    }
    return 0;
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 1,
      height: 'calc(100vh - 100px)',
      overflow: 'hidden'
    }}>
      <Box sx={{ flex: 1, overflow: 'auto', pr: 2 }}>
        <ModulesTable
          modules={modulesData}
          selectedTier={selectedTier}
          selectedModules={selectedModules}
          userCounts={userCounts}
          enterpriseSeats={enterpriseSeats}
          excludeAIMe={excludeAIMe}
          onModuleSelect={handleModuleSelect}
          onUserCountChange={handleUserCountChange}
          onEnterpriseSeatsChange={setEnterpriseSeats}
          onTierChange={handleTierChange}
          onExcludeAIMeChange={handleExcludeAIMeChange}
          includedLicenses={includedLicenses}
        />

        <AddOns
          addOns={addOnsData}
          selectedTier={selectedTier}
          selectedAddOns={selectedAddOns}
          userCounts={userCounts}
          enterpriseSeats={enterpriseSeats}
          dataSources={dataSources}
          onAddOnSelect={handleAddOnSelect}
          onUserCountChange={handleUserCountChange}
          onDataSourcesChange={setDataSources}
        />
      </Box>

      <Box sx={{ width: 350, flexShrink: 0, overflow: 'auto' }}>
        <PricingSummary
          selectedTier={selectedTier}
          selectedModules={selectedModules}
          selectedAddOns={selectedAddOns}
          moduleCosts={calculateModuleCosts()}
          moduleCostsBeforeDiscount={calculateModuleCostsBeforeDiscount()}
          discountAmount={calculateDiscountAmount()}
          addOnCosts={calculateAddOnCosts()}
          discountPercentage={getRawDiscountPercentage()}
          yearTerm={yearTerm}
          onYearTermChange={setYearTerm}
          totalCost={calculateTotalCost()}
          standardIncreaseRate={0.07 * 100}
          priceIncreaseRate={getPriceIncreaseRate(yearTerm) * 100}
          enterpriseSeats={enterpriseSeats}
          perSeatPrice={selectedTier === 'enterprise' ? getEnterpriseUserPrice(enterpriseSeats) : null}
          excludeAIMe={excludeAIMe}
          includedLicenses={includedLicenses}
          modulesData={modulesData}
          userCounts={userCounts}
          platformFee={getPlatformFee()}
          licenseCosts={calculateLicenseCosts()}
        />
      </Box>
    </Box>
  );
};

export default PricingCalculator;