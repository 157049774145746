import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  IconButton,
  CircularProgress
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';
import { authedApiFetch } from "../../utils/Api";

const MappingReview = ({ 
  fileName, 
  fileId, 
  tenantId,
  onSaveMapping, 
  onApproveMapping, 
  onUpdateMapping 
}) => {
  const [editDialog, setEditDialog] = useState(null);
  const [previewColumns, setPreviewColumns] = useState([]);
  const [previewRows, setPreviewRows] = useState([]);
  const [sourceHeaders, setSourceHeaders] = useState([]);
  const [mappings, setMappings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadMappingAndPreview() {
      setLoading(true);
      try {
        // Fetch mapping data
        const mappingResponse = await authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files/${fileId}/show_mapping`,
          method: "GET"
        });

        // Fetch preview data
        const previewResponse = await authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files/${fileId}/show_preview`,
          method: "GET"
        });

        // Set state with fetched data
        setSourceHeaders(mappingResponse.sourceHeaders || []);
        setMappings(mappingResponse.mappings || {});
        
        // Process preview data for DataGrid
        if (previewResponse && previewResponse.rows && previewResponse.rows.length > 0) {
          processPreviewData(previewResponse.rows, mappingResponse.mappings);
        } else {
          console.error('No preview data available');
          setPreviewRows([]);
          setPreviewColumns([]);
        }
      } catch (err) {
        console.error('Error loading mapping data:', err);
        setError('Failed to load mapping data. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    if (fileId && tenantId) {
      loadMappingAndPreview();
    }
  }, [fileId, tenantId]);

  // Process preview data and set up DataGrid columns/rows
  const processPreviewData = (previewData, mappingData) => {
    if (!previewData || previewData.length === 0) {
      setPreviewColumns([]);
      setPreviewRows([]);
      return;
    }

    // Create columns for the preview - only show target fields that have a mapping (not SKIP)
    const columns = Object.keys(mappingData || {})
      .filter(targetField => mappingData[targetField].sourceHeader && mappingData[targetField].sourceHeader !== 'SKIP')
      .map(targetField => ({
        field: targetField,
        headerName: targetField,
        width: 180,
        renderHeader: (params) => (
          <Box>
            <Typography variant="subtitle2">{params.colDef.headerName}</Typography>
            <Typography variant="caption" color="textSecondary">
              {mappingData[params.colDef.field].sourceHeader}
            </Typography>
          </Box>
        )
      }));

    setPreviewColumns(columns);

    // Create rows from preview data
    const rows = previewData.map((row, index) => {
      const transformedRow = { id: index };
      
      // For each target field with a valid mapping, get the value from the source column
      Object.entries(mappingData || {}).forEach(([targetField, mapping]) => {
        const sourceHeader = mapping.sourceHeader;
        if (sourceHeader && sourceHeader !== 'SKIP' && row[sourceHeader] !== undefined) {
          transformedRow[targetField] = row[sourceHeader];
        }
      });
      
      return transformedRow;
    });

    setPreviewRows(rows);
  };

  const handleEditMapping = (targetField) => {
    setEditDialog({
      targetField,
      currentMapping: mappings[targetField]?.sourceHeader || '',
      sourceHeader: mappings[targetField]?.sourceHeader || ''
    });
  };

  const handleUpdateMapping = () => {
    if (editDialog) {
      // Update local state
      const updatedMappings = {
        ...mappings,
        [editDialog.targetField]: {
          ...mappings[editDialog.targetField],
          sourceHeader: editDialog.sourceHeader
        }
      };
      
      setMappings(updatedMappings);
      
      // Call parent handler
      if (onUpdateMapping) {
        onUpdateMapping(editDialog.targetField, editDialog.sourceHeader);
      }
      
      // Close dialog
      setEditDialog(null);
      
      // Update preview data with new mapping
      if (previewRows.length > 0) {
        processPreviewData(previewRows.map(row => ({ ...row })), updatedMappings);
      }
    }
  };

  const getConfidenceColor = (confidence) => {
    if (confidence >= 0.9) return 'success';
    if (confidence >= 0.7) return 'warning';
    return 'error';
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Mapping Review: {fileName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Review and confirm the field mappings between your source file and our data model
        </Typography>
      </Box>

      {/* Mapping Table */}
      <Paper sx={{ mb: 4 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Target Field</TableCell>
                <TableCell>Source Header</TableCell>
                <TableCell>Confidence</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(mappings).map(([targetField, mapping]) => (
                <TableRow key={targetField}>
                  <TableCell>
                    <Typography variant="body2" fontWeight="medium">
                      {targetField}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {mapping.sourceHeader === 'SKIP' ? (
                      <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Skipped
                      </Typography>
                    ) : mapping.sourceHeader ? (
                      <Typography variant="body2">
                        {mapping.sourceHeader}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="error">
                        Not Mapped
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {mapping.sourceHeader === 'SKIP' ? (
                      <Chip
                        size="small"
                        label="Skipped"
                        color="default"
                      />
                    ) : mapping.confidence && (
                      <Chip
                        size="small"
                        icon={<AutoFixHighIcon />}
                        label={`${Math.round(mapping.confidence * 100)}%`}
                        color={getConfidenceColor(mapping.confidence)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditMapping(targetField)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Data Preview */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Data Preview
      </Typography>

      // TODO: replace this data grid with the databricks datagrid for better formatting

      <Box sx={{ height: 400, mb: 4 }}>
        {previewRows.length > 0 ? (
          <DataGrid
            autosizeOnMount
            rows={previewRows}
            columns={previewColumns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 },
              },
            }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        ) : (
          <Typography color="textSecondary">No preview data available</Typography>
        )}
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={() => onSaveMapping(mappings)}
        >
          Save Mapping Template
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckCircleOutlineIcon />}
          onClick={() => onApproveMapping(mappings)}
        >
          Approve and Process
        </Button>
      </Box>

      {/* Edit Mapping Dialog */}
      <Dialog 
        open={!!editDialog} 
        onClose={() => setEditDialog(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Field Mapping
          <IconButton
            aria-label="close"
            onClick={() => setEditDialog(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Target Field
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {editDialog?.targetField}
            </Typography>
            <Autocomplete
              value={editDialog?.sourceHeader || ''}
              onChange={(event, newValue) => {
                setEditDialog(prev => ({
                  ...prev,
                  sourceHeader: newValue
                }));
              }}
              options={['SKIP', ...sourceHeaders]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source Header"
                  fullWidth
                />
              )}
            />
            {editDialog?.sourceHeader === 'SKIP' && (
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                This field will be excluded from the mapping.
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(null)}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            onClick={handleUpdateMapping}
          >
            Update Mapping
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MappingReview;